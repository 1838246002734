export const baseUrl = "https://aava-rabbit-restapi.herokuapp.com/api/v1/rabbit";
//export const baseUrl = "https://rabbit-test-backend.herokuapp.com/api/v1/rabbit";
//export const baseUrl = "http://localhost:3002/api/v1/rabbit";

export const createAuthMiddleWare = (dispatch,data2) => {
    
    return (res) => {

        if (res.status === 401) {
            //dispatch({ type: "auth_renew", payload: {} });
            dispatch({ type: "sign_out", payload: {} });
        }
        return res;
        
    };
}