import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import DevicePage from './components/device/devices-page';
import NavBar from './components/common/navbar';
import LoginPage from './components/login/login-page';
import { useAuthState } from "./state/AuthState";
import { useEffect } from "react"; 
import { REFRESH_TOKEN, ACCESS_TOKEN, USER } from "./constants";
import DeviceInfoPage from "./components/device/device-info-page";
import { DeviceStateProvider, initialState } from './state/DeviceState'

const PrivateRoute = ({ component, path, exact }) => {
    const { state } = useAuthState();
    const { dispatchAuth } = useAuthState();
    const history = useHistory();

    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const userName = localStorage.getItem(USER);
    const signedIn = (state && state.accessToken) || accessToken;

    useEffect(() => {
        
        if (!accessToken) {
            history.push("/login");
        } else {
            dispatchAuth({ type: "sign_in", payload: { userName, accessToken, refreshToken } });
        }
    }, [userName, accessToken, refreshToken]);

    return signedIn ?
        (<Route
            path={path}
            exact
            component={component}
        />) : <Redirect to="/login" />
}

const AuthenticatedApp = () => {
    const history = useHistory();
    const { dispatchAuth } = useAuthState();

    const onSignOut = () => {
    console.log("Authenticated App onSingnIout");
        dispatchAuth({ type: "sign_out" });
        history.push("/login");
    }

    const NotFound = () => (
        <div style={{ marginTop: 80 }}>
            <Typography variant="h2">Not found!</Typography>
            <Button color="primary" variant="contained" onClick={() => history.push("/login")} style={{ marginTop: 16 }}>
                Login page
            </Button>
        </div>
    );

    return (
        <div>
            <DeviceStateProvider value={initialState}>
                <NavBar onSignOut={onSignOut} />
                <Switch>
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
                    <PrivateRoute exact path="/" component={DevicePage} />
                    <PrivateRoute exact path="/devices" component={DevicePage} />
                    <PrivateRoute exact path="/devices/:deviceId" component={DeviceInfoPage} />
                    <Route component={NotFound}/>
                </Switch>
            </DeviceStateProvider>
        </div>

    );
}

export default AuthenticatedApp;