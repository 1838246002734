import { REFRESH_TOKEN, ACCESS_TOKEN, USER } from "../constants";
import { baseUrl } from "../data/api";

export const reduce = (state, action) => {
    switch (action.type) {
        case "sign_out": {
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(USER);
            return {
                userName: null,
                refreshToken: null,
                accessToken: null
            }
        }
        case "sign_in": {
            var { userName, accessToken, refreshToken } = action.payload;
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
            localStorage.setItem(USER, userName);
            return {
                userName,
                accessToken, 
                refreshToken
            }
        }
        case "auth_renew": {
          const refreshToken = localStorage.getItem(REFRESH_TOKEN);
            console.log("AuthReducer auth_renew " +refreshToken);
            const body = { refreshToken:refreshToken };

            fetch(`${baseUrl}/login`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },

              body: JSON.stringify(body)
              })
              .then(res => {
                console.error("ret:", res.status);
                  if (res.status === 200) {
                    return res.json()
                  } else {
                    return null
                  }
              })
              .then(data => {
                  console.error("accessToken:", data.accessToken);
                  
                  if (data.accessToken && data.refreshToken) {
                    localStorage.setItem(ACCESS_TOKEN, data.accessToken);
                    //dispatchAuth({ type: "sign_in", payload:{ userName, accessToken: token.accessToken, refreshToken: token.refreshToken } });
                    //history.push("/devices");
                  } 
              })
              .catch(e => {
                  console.error("Error logging in:", e);
              })
      

            return {
                ...state,
                refreshToken: action.payload
            }
        }          
        default:
            return state;
    }
}