import React, { useState } from "react";
import { CircularProgress, Typography, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import DeviceList from "./device-list";
//import DeviceMapView from "./device-map-view";
import DeviceSummaryInfo from "./device-summary-info";
import { useEffect } from "react";
import { ACCESS_TOKEN } from "../../constants";
import { createAuthMiddleWare, baseUrl } from "../../data/api";
import { useAuthState } from "../../state/AuthState";
import { useDeviceState } from "../../state/DeviceState";

import "./devices-page.scss";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DevicePage = () => {
    /* eslint-disable no-unused-vars */
    const { state, dispatchAuth } = useAuthState();
    const [devicesLoading, setDevicesLoading] = useState(true);
    const [listView, setListView] = useState(true);
    const [errorSnackOpen, showErrorSnack] = useState(false);
    const [snackBarText, setSnackBarText] = useState("");
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const { devicesState, dispatch } = useDeviceState();
    const authMiddleWare = createAuthMiddleWare(dispatchAuth);
    const [searchWord, setSearchWord] = useState("");
    const [perPage, setPerPage] = useState(20);
    const [page, setPage] = useState(1);

    useEffect(() => {
      fetch(
        `${baseUrl}/devices?&page=${page ? page : "1"}&perpage=${
          perPage.target ? perPage.target.value : "20"
        }`,
        {
          headers: {
            authorization: `bearer ${accessToken}`,
          },
        }
      )
        .then(authMiddleWare)
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            return null;
          }
        })
        .then((json) => {
          setDevicesLoading(false);
          if (json) {
            dispatch({ type: "DEVICE_STATE_CHANGED", payload: json });
          }
        })
        .catch((e) => {
          console.error(e);
          setSnackBarText("Failed to retrieve device data!");
          showErrorSnack(true);
          setDevicesLoading(false);
        });

      fetch(`${baseUrl}/devices`, {
        headers: {
          authorization: `bearer ${accessToken}`,
        },
      })
        .then(authMiddleWare)
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            return null;
          }
        })
        .then((json) => {
          setDevicesLoading(false);
          if (json) {
            dispatch({
              type: "SET_NOTIFICATION_DATA",
              payload: json.devices.filter(
                (item) => item["stateOfHealth"] !== "BATTERY_HEALTH_GOOD"
              ),
            });
          }
        })
        .catch((e) => {
          setSnackBarText("Failed to retrieve notification data!");
          showErrorSnack(true);
        });

      fetch(`${baseUrl}/summary`, {
        headers: {
          authorization: `bearer ${accessToken}`,
        },
      })
        .then(authMiddleWare)
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            return null;
          }
        })
        .then((json) => {
          if (json) {
            dispatch({ type: "SET_SUMMARY_DATA", payload: json });
          }
        })
        .catch((e) => {
          setSnackBarText("Failed to retrieve summary data!");
          showErrorSnack(true);
          console.error(e);
        });
    }, [accessToken, perPage, page]);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        showErrorSnack(false);
    };

    const handleSearchChange = (event) => {
        setSearchWord(event.target.value);
    }

    const  changeListType = (event) => {

      if(event.target.value==="1"){
        setListView(true);
      } else {
        setListView(false);
      }
  }
   
    return (
        <div className="device-page">
            <Typography style={{ margin: 24, alignSelf: "flex-start" }} variant="h3">
                <b>Devices</b>
            </Typography>
            {
            devicesLoading ? null :
               <div><label>List View</label>
                <input type="radio" name="listviewtype" value="1" checked={listView===true} onChange={changeListType}/>
                <label>&nbsp;&nbsp;&nbsp;Map View</label>
                <input type="radio" name="listviewtype" value="0" checked={listView===false} onChange={changeListType}/>
                </div>
            }
            
            <DeviceSummaryInfo summaryData={devicesState ? devicesState.summaryData : undefined} handleSearchChange={handleSearchChange} searchWord={searchWord} />
            {
               devicesLoading ? 
                  <CircularProgress style={{ marginLeft: "auto", marginRight: "auto" }} />
                : [
                  listView ?
                  <DeviceList searchWord={searchWord} setPerPage={setPerPage} setPage={setPage} perPage={perPage} page={page}/>
                  :
                  <DeviceList searchWord={searchWord} setPerPage={setPerPage} setPage={setPage} perPage={perPage} page={page}/>
                  ]
            }

            <Snackbar open={errorSnackOpen} autoHideDuration={3000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity="error">
                    {snackBarText}
            </Alert>
            </Snackbar>
        </div>
    );
}

export default DevicePage;