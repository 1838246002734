import React, { useEffect, useState } from "react";
import { useAuthState } from "../../state/AuthState";
import { useDeviceState } from "../../state/DeviceState";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { Badge, Typography } from '@material-ui/core';
import Notifications from '../notifications/Notifications';
import "./navbar.scss"
import { Menu, MenuItem } from "@material-ui/core";
import logo from "../common/AavaLogo.png"

const NavBar = ({ onSignOut }) => {
    const { state } = useAuthState();
    const { devicesState } = useDeviceState();
    const signedIn = state && state.accessToken;
    const [anchorEl, setAnchorEl] = useState(null);
    const [showNotifications, setShowNotifications] = useState(false);
    const notificationlist = devicesState.notificationData ? devicesState.notificationData : [];
    const [notifications, setNotifications] = useState(notificationlist.length);

    useEffect(() => {
        setNotifications(notificationlist.length);
    }, [notificationlist])

    
    const menuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onLogoutClick = () => {
        setAnchorEl(null);
        onSignOut();
    };

    const noficationIcon = (notifications) => {
        if (notifications < 1) {
            return (
                <Badge className="badge" badgeContent={notifications} onClick={() => setShowNotifications(!showNotifications)} color="error">
                    <NotificationsIcon className="notification-icon1" />
                </Badge>
            )
        } else {
            return (
                <Badge className="badge" badgeContent={notifications} onClick={() => setShowNotifications(!showNotifications)} color="error">
                    <NotificationImportantIcon className="notification-icon1" />
                </Badge>
            )
        }
    }

    const removeNotificationAlert = () => {
        if (notifications > 0) {
            setNotifications(notifications - 1);
        }
    }

    return (
        <header className="navbar">
            
            <img src={logo} alt="Aava Logo" className="logo"/>
            <h2 className="app-name">Aava Rabbit</h2>
            {signedIn && noficationIcon(notifications)}
            {signedIn && notificationlist &&
                <Notifications
                    setShowNotifications={setShowNotifications}
                    show={showNotifications}
                    notificationlist={notificationlist}
                    removeNotificationAlert={removeNotificationAlert} />}
            {signedIn && <div className="user" onClick={menuClick}>
                <AccountCircleIcon style={{ fill: "#FFFFFF"}} />
                <Typography className="user-name"  variant="h5">{state.userName}</Typography>
            </div>}
            <Menu
                id="user-menu"
                className="user-menu"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
            >
                <MenuItem className="menu-item" onClick={onLogoutClick}>Log out</MenuItem>
            </Menu>
           
        </header>
    );

}

export default NavBar;
