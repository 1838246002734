import React, { useState } from "react";
import { Typography, Button, ButtonGroup, IconButton, CircularProgress } from "@material-ui/core";
import { BarChart, LineChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import "./battery-info.scss";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';
import { startOfWeek, getISOWeek } from 'date-fns'

const BatteryInfo = (props) => {
  const { batteryData,
    handleIntervalChange,
    activeInterval,
    intervals,
    handleIntervalChangeLeft,
    handleIntervalChangeRight,
    batteryHistoryLoading,
    handleStartDayChange,
    handleEndDayChange,
    intervalStart,
    intervalEnd,
    calculateEnd } = props
  const [chartType, setChartType] = useState("line");
  //const device = devicesState.devices.find(device => device.deviceId === deviceId);
  const FORMAT = 'dd/MM/yyyy';
    
  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  const changeChart = () => {
    if (chartType === "line") {
      setChartType("bar");
    } else {
      setChartType("line");
    }
  }

  const mappedData = batteryData ? batteryData.map(data => {
    return {
      time: data["timeToCreate"],
      temperature: data["temperature"],
      level: data["level"]
    }
  }) : [];

  const graph = () => {
    if (!mappedData || mappedData.length === 0) {
      return (<Typography style={{ width: "90%", justifySelf: "center" }} variant="h6">
        No data in range
      </Typography>
      );
    }

    const tickFormatter = (tickItem) => {
      switch (activeInterval) {
        case "day":
          return tickItem.split(" ")[1]
        case "month":
        case "all":
        case "week":
        default:
          return tickItem.split(" ")[0];
      }
    }

    
    if (chartType === "line") {
      return (
        <ResponsiveContainer width="90%" height="100%">
          <LineChart
            style={{ marginLeft: -20, marginRight: "auto" }}
            width={800}
            height={400}
            data={mappedData}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tickFormatter={tickFormatter} interval={1000} dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line dot={false} dataKey="level" stroke="#FF2D00" />
            <Line dot={false} dataKey="temperature" stroke="#0037FF" />
          </LineChart>
        </ResponsiveContainer>
      )
    } else {
      return (
        <ResponsiveContainer width="90%" height="100%">
          <BarChart
            style={{ marginLeft: -20, marginRight: "auto" }}
            width={800}
            height={400}
            data={mappedData}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={10} dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="level" fill="#FF2D00" />
            <Bar dataKey="temperature" fill="#0037FF" />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  }
  const renderGraph = () => {
    if (activeInterval !== "all" && activeInterval !== "custom") {
      return <div className="data-chart-container">
          {graph()}
      </div>
    }

    return graph();
  }

  return (
    <div>
      <div className="chart-container">
        <Typography
          style={{ marginBottom: 16, marginTop: 16 }}
          variant="h6"
        >
          <b>
            Battery Data History
          </b>
        </Typography>
        <div className="button-message-container">
          <ButtonGroup
            aria-label="outlined primary button group"
          >
            {intervals.map((interval) => (
              <Button
                color="primary"
                variant={`${interval.value === activeInterval
                  ? "contained"
                  : "outlined"
                  }`}
                key={interval.value}
                onClick={() => handleIntervalChange(interval.value)}
              >
                {interval.label}
              </Button>
            ))}
          </ButtonGroup>
        <IconButton
            className="button-left"
            onClick={() => handleIntervalChangeLeft()}
        >
            <ArrowBackIosIcon />
        </IconButton>
          {activeInterval === "custom" ? (
                      <div>
                        <DayPickerInput
                          onDayChange={handleStartDayChange}
                          selectedDay={intervalStart}
                          style={{ marginLeft: 20, marginRight: 20 }}
                          formatDate={formatDate}
                          format={FORMAT}
                          placeholder={dateFnsFormat(intervalStart, FORMAT)}
                        />
                        -
                        <DayPickerInput
                          onDayChange={handleEndDayChange}
                          selectedDay={intervalEnd}
                          style={{ marginLeft: 20 }}
                          formatDate={formatDate}
                          format={FORMAT}
                          placeholder={dateFnsFormat(intervalEnd, FORMAT)}
                        />
                      </div>
                    ) : activeInterval !== "all" ? (
                      <Typography style={{ marginLeft: 50 }} variant="h6">
                        Week{" "}
                        {getISOWeek(
                          startOfWeek(intervalStart, { weekStartsOn: 1 }),
                          {
                            weekStartsOn: 1,
                          }
                        )}{" "}
                        {intervalStart.toString().slice(3, 10)} -{" "}
                        {calculateEnd().toString().slice(3, 15)}
                      </Typography>
                    ) : null}
            <IconButton
                className="button-right"
                onClick={() => handleIntervalChangeRight()}
            >
                <ArrowForwardIosIcon />
            </IconButton>
          <IconButton onClick={changeChart} style={{ marginLeft: "auto", marginRight: 20 }}>
            {chartType === "line" ? <EqualizerIcon style={{ fill: "black" }} /> : <ShowChartIcon style={{ fill: "black" }} />}
          </IconButton>
        </div>
        {batteryHistoryLoading ? <div style={{ width: "100%", height: "100%" }}><CircularProgress style={{ marginTop: 180 }} /></div> : renderGraph()}
      </div>
    </div>
  );
}

export default BatteryInfo;