import { createTheme } from '@material-ui/core/styles';

export const USER = "userName";
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const BATTERY_LOW_MAX_VALUE = 80;
export const BATTERY_DEAD_MAX_VALUE = 60;

// Material ui theming
// https://material-ui.com/customization/theming/

export const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: "#0086a8",
            dark: '#002884',
            contrastText: '#fff ',
        },
        secondary: {
            light: "#4f5b62",
            main: "#263238",
            dark: "#000a12",
        },
    },
    typography: {
        h5: {
            color: "#FFFFFF"
        }
    }
});
