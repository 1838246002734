import React from "react";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import {BATTERY_LOW_MAX_VALUE,BATTERY_DEAD_MAX_VALUE} from '../../constants'
import "../device/device-summary.scss"


const BatteryHealthIcon = ({ health }) => {

    if(health>BATTERY_LOW_MAX_VALUE)
        return <ThumbUpIcon className="good-color"/>
    else if (health>BATTERY_DEAD_MAX_VALUE)
        return <BatteryAlertIcon className="low-color"/>
    else 
        return <BatteryAlertIcon className="bad-color"/>
}

export default BatteryHealthIcon;