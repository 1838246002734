import { Typography } from "@material-ui/core";
import React from "react";

import "./info-row.scss";

const InfoRowTable = ({ label, value }) => {
    return (
        <div className="info-row">
            <Typography variant="subtitle2">
                {label}
            </Typography>
            <Typography variant="h6" align="left">
                {value.length ? value.map((l,index) => 
                   <div key={index}>-  {l.name}  "{l.address}"</div>
                ):<div>--</div>}
            </Typography>
        </div>
    );
}

export default InfoRowTable;