import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import capitalize from "../common/capitalize";
//import TablePagination from '@material-ui/core/TablePagination';
import { useDeviceState } from "../../state/DeviceState";
import DeviceRow from "./device-row";

import "./device-list.scss";

const labels = ["Serial number", "Last seen", "Sales box code"]

//const DeviceList = ({ searchWord, setPerPage, setPage, perPage, page }) => {
const DeviceList = ({ searchWord, setPerPage, setPage}) => {
  const [orderBy, setOrderBy] = useState("battery");
  const [orderAsc, setOrderAsc] = useState(true);
  const { devicesState } = useDeviceState();
  const devices = devicesState.devices ? devicesState.devices : [];
  
  const orderChange = (order) => {
    if (orderBy === order) {
      setOrderAsc(!orderAsc);
    } else {
      setOrderBy(order);
      setOrderAsc(true);
    }
  };

  const getTableSort = () => {
    if (!orderBy) {
      return undefined;
    } else {
      switch (orderBy) {
        case "id": {
          if (orderAsc) {
            return (a, b) => (b.deviceId > a.deviceId ? 1 : -1);
          } else {
            return (a, b) => (a.deviceId > b.deviceId ? 1 : -1);
          }
        }
        case "battery": {
          if (orderAsc) {
            return (a, b) => (Number(b.level) > Number(a.level) ? 1 : -1);
          } else {
            return (a, b) => (Number(a.level) > Number(b.level) ? 1 : -1);
          }
        }
        case "temp": {
          if (orderAsc) {
            return (a, b) =>
              Number(b.temperature) > Number(a.temperature) ? 1 : -1;
          } else {
            return (a, b) =>
              Number(a.temperature) > Number(b.temperature) ? 1 : -1;
          }
        }
        case "Last seen": {
          if (orderAsc) {
            return (a, b) =>
              b.lastSeen > a.lastSeen ? 1 : -1;
          } else {
            return (a, b) =>
              a.lastSeen > b.lastSeen ? 1 : -1;
          }
        }
        case "Sales box code": {
          if (orderAsc) {
            return (a, b) =>
              b.salesBoxCode > a.salesBoxCode ? 1 : -1;
          } else {
            return (a, b) =>
              a.salesBoxCode > b.salesBoxCode ? 1 : -1;
          }
        }
        default: {
          return undefined;
        }
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: 550 }}>
        <TableHead>
          <TableRow>
            {labels.map((l,index) => (
              <TableCell
                key={index}
                sortDirection={orderBy === l ? orderAsc ? "asc" : "desc" : false}
                align="center"
                onClick={() => orderChange(l)}>
                <TableSortLabel
                  direction={orderAsc ? "asc" : "desc"}
                  active={orderBy === l}
                >
                  <b>{capitalize(l)}</b>
                  {orderBy === l ? (
                    <span className="visually-hidden">
                      {orderAsc ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="center">
              <b>Battery Health</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices &&
            devices
              .filter((device) => device.serialNumber.includes(searchWord))
              .sort(getTableSort())
              .map((d,index) => <DeviceRow key={index} device={d} />)}
        </TableBody>
{/*     <TablePagination 
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={devicesState.totalCount}
          rowsPerPage={perPage.target ? perPage.target.value : 50}
          page={page ? page - 1 : 1} // api starts paging from 1 instead of 0
          onChangePage={(event, newPage) => setPage(newPage+1)}
          onChangeRowsPerPage={(perPage) => {setPerPage(perPage);setPage(1);}}
          /> */}
      </Table>
    </TableContainer>
  );
};

export default DeviceList;
