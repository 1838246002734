import {BATTERY_LOW_MAX_VALUE} from "../constants"

export const reduce = (state, action) => {
    switch (action.type) {
        case "DEVICE_STATE_CHANGED": {
            const devicesData = action.payload;
            const filteredNewData = devicesData.devices.reduce((unique, o) => {
                if (!unique.some(obj => obj.deviceId === o.deviceId)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            return {
                ...state,
                devices: filteredNewData,
                page: devicesData.page,
                totalCount: devicesData.totalNumberOfDevices,
            }
        }
        case "SET_SUMMARY_DATA": {
            return { ...state, summaryData: action.payload };
        }
        case "SET_NOTIFICATION_DATA": {
            const { notificationData } = state;
            let newData = action.payload;

            if (notificationData) {
                newData = [...newData, ...notificationData];
            }

            // Remove duplicates by id
            const filteredNewData = newData.reduce((unique, o) => {

                if (!unique.some(obj => obj.deviceId === o.deviceId)) {
                    if(o.batteryHealth<BATTERY_LOW_MAX_VALUE)
                        unique.push(o);
                }
                return unique;
            }, []);

            return { ...state, notificationData: filteredNewData };
        }
        default:
            return state;
    }
}