import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from "react";
import { useHistory } from "react-router-dom";
import BatteryHealthIcon from "../common/battery-health-icon";
import "./device-row.scss";

const DeviceRow = ({ device }) => {
    const history = useHistory();
    let lastSeenDay = "";
    let lastSeenClock = "";

    if (device.lastSeen !== undefined) {
      lastSeenDay = device.lastSeen.slice(0, 10);
      //lastSeenClock = device.lastSeen.slice(11, 16);
      lastSeenClock = new Date(device.lastSeen).getHours().toLocaleString() + ":" + new Date(device.lastSeen).getMinutes(); ;
    } else {
      lastSeenDay = "No";
      lastSeenClock = "Data";
    }


    const openDeviceInfo = () => {
        history.push(`/devices/${device.deviceId}`);
    }

    return (
        <TableRow
        className="device-row"
            onClick={() => openDeviceInfo()}
            key={device.deviceId.toLocaleString()}>
           <TableCell align="center" component="th" scope="row">
               {device.serialNumber}
            </TableCell>
            <TableCell align="center" style={{paddingRight: 38}}>
                {lastSeenDay}
                <br/>
                {lastSeenClock}
            </TableCell>
            <TableCell align="center" style={{paddingRight: 50}}>
                {device.salesBoxCode}
            </TableCell>
            <TableCell align="center" style={{paddingLeft: 25}}>
                <BatteryHealthIcon health={device.batteryHealth} />
            </TableCell>
        </TableRow>
    );
}

export default DeviceRow;