import './App.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthStateProvider, initialState } from './state/AuthState';
import AuthenticatedApp from './AuthenticatedApp';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './constants';

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Router>
          <AuthStateProvider value={initialState}>
            <ThemeProvider theme={theme}>
              <AuthenticatedApp />
              </ThemeProvider>
          </AuthStateProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
