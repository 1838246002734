import React from "react";
import InfoRow from "../common/info-row";
import { Divider } from "@material-ui/core";

import "./device-row.scss";

const AndroidInfo = (props) => {
    let device = props.data;

    return (<div>
            <InfoRow label={"OS version"} value={device.os + " " + device.osVersion} />
                 <Divider />
                 <InfoRow label={"Release Name (Finger print)"} value={device.swReleaseName} />
                 <Divider />
                 <InfoRow label={"Android sw customization"} value={device.android.swCustomization} />
                 <Divider />
                 <InfoRow label={"Android board version"} value={device.android.boardVersion} />
                 <Divider />
                 <InfoRow label={"Android touch firmware version"} value={device.android.touchFwVersion} />
                 <Divider />
                 <InfoRow label={"Android touch config type"} value={device.android.touchConfigType} />
                 <Divider />
                 <InfoRow label={"EC firmware version"} value={device.ec.ecVersion} />
                 <Divider />
                 <InfoRow label={"EC customization version"} value={device.ec.ecCustomizationVersion} />
                 <Divider />
                 </div>
    );
};

const WindowsInfo = (props) => {
    let device = props.data;

    return (<div>
         <InfoRow label={"OS version"} value={device.os + " " + device.osVersion} />
              <Divider />
              <InfoRow label={"Release Name"} value={device.swReleaseName} />
              <Divider />
              <InfoRow label={"EC firmware version"} value={device.ec.ecVersion} />
              <Divider />
              <InfoRow label={"EC customization version"} value={device.ec.ecCustomizationVersion} />
              <Divider />
              <InfoRow label={"Windows touch firmware version"} value={device.windows.touchConfigVersion} />
              <Divider />
              <InfoRow label={"Windows bios version"} value={device.windows.biosVersion} />
              <Divider />
              <InfoRow label={"Windows bios customization"} value={device.windows.biosCustomization} />
              <Divider />
              <InfoRow label={"Windows sub serial number"} value={device.windows.subSerial} />
              <Divider /></div>
    );
}

const SwInfoPanel = ( props ) => {
    let android = false;
   

    if (props.device.os.includes('Android') ) android = true;

    return (
        <div>
        {android ? <AndroidInfo data={props.device}/> : <WindowsInfo data={props.device}/>}
        </div>
    );
}

export default SwInfoPanel;