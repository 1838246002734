import React, {  useRef, useEffect } from "react";
import "./Notifications.scss";
import { useHistory } from "react-router-dom";
import {BATTERY_LOW_MAX_VALUE,BATTERY_DEAD_MAX_VALUE} from "../../constants"

const Notification = ({ show, setShowNotifications, notificationlist, removeNotificationAlert }) => {
  const history = useHistory();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowNotifications(!show);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  });

  const rowClick = (item) => {
    removeNotificationAlert();
    setShowNotifications(false)
    history.push(`/devices/${item.deviceId}`);
  };

  
  const notificationMessage = (item) => {
    if(item['batteryHealth'] < BATTERY_DEAD_MAX_VALUE){
      return (
        <p className="notification-row-content">
          Device {item.serialNumber} battery healt dead!
        </p>
      )
    } else if(item['batteryHealth'] < BATTERY_LOW_MAX_VALUE){
      return (
        <p className="notification-row-content">
          Device {item.serialNumber} battery healt low!
        </p>
      )
    } else {
      return null;
    }
  }

  if (show) {
    return (
      <div ref={ref} className="notificationBox">
        {notificationlist.map((item, i) => (
          <div className="notification-row" key={i} onClick={() => rowClick(item)} >
            <div className="notification-content">
              {notificationMessage(item)}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default Notification;
