import { Typography, TextField} from "@material-ui/core";
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import React from "react";

import "./device-summary.scss"

const DeviceSummaryInfo = ({ summaryData, handleSearchChange, searchWord  }) => {
    if (!summaryData) {
        return <div />
    }
    const { batterySummary, deviceSummary } = summaryData;

    return (
      <div className="summary-row">
        <div className="summary-search-bar">
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            value={searchWord}
            onChange={handleSearchChange}
          />
        </div>
        <div className="summary-row-item">
          <SmartphoneIcon className="good-color"/>
          <Typography variant="h6">{batterySummary.BATTERY_HEALTH_GOOD}</Typography>
        </div>
        <div className="summary-row-item">
          <BatteryAlertIcon className="low-color"/>
          <Typography variant="h6">{batterySummary.BATTERY_HEALTH_LOW}</Typography>
        </div>
        <div className="summary-row-item">
          <BatteryAlertIcon className="bad-color"/>
          <Typography variant="h6">{batterySummary.BATTERY_HEALTH_BAD}</Typography>
        </div>
        <div className="summary-row-item">
          <BatteryUnknownIcon />
          <Typography variant="h6">{batterySummary.BATTERY_HEALTH_UNKNOWN}
          </Typography>
        </div>
      </div>
    );
}

export default DeviceSummaryInfo;