import React, { createContext, useReducer, useContext } from "react";
import { reduce } from "./DeviceReducer";

export const initialState = {
    devices: [],
    summaryData: undefined,
    perPage: 20,
    totalCount: 0,
    page: 1,
};

const DeviceContext = createContext([initialState, () => null]);

export const DeviceStateProvider = ({ children, initState }) => {
    const [state, dispatch]  = useReducer(reduce, initState || initialState);

    return <DeviceContext.Provider value={[state, dispatch]}>{children}</DeviceContext.Provider>;
}

export const useDeviceState = () => {
    const [devicesState, dispatch] = useContext(DeviceContext);

    return { devicesState, dispatch }
}