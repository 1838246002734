import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Paper, TextField, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { baseUrl } from "../../data/api";
import "./login-page.scss"
import { useAuthState } from "../../state/AuthState";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginPage = () => {
    const [userName, setUserName] = useState("");
    const [passWord, setPassword] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errorSnackOpen, showErrorSnack] = useState(false);
    const { dispatchAuth } = useAuthState();
    const history = useHistory();

    const onClick = () => {
        const isPasswordEmpty = passWord === "";
        const isUsernameEmpty = userName === "";
        if (isUsernameEmpty) {
            setUsernameError(true);
        }
        if (isPasswordEmpty) {
            setPasswordError(true);
        }
        if (isUsernameEmpty || isPasswordEmpty) {
            return;
        }
        const body = { userName:userName, passWord:passWord };
        fetch(`${baseUrl}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
            })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                return null
                }
            })
            .then(token => {
                if (token.accessToken && token.refreshToken) {
                  dispatchAuth({ type: "sign_in", payload:{ userName, accessToken: token.accessToken, refreshToken: token.refreshToken } });
                  history.push("/devices");
                } else {
                  showErrorSnack(true);
                }
            })
            .catch(e => {
                console.error("Error logging in:", e);
            })
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        showErrorSnack(false);
    };

    const changeUsername = (e) => {
        setUserName(e.target.value);
        setUsernameError(false);
    }

    const changePassword = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            onClick();
        }
    }

    return (
        <Container maxWidth="sm" className="login-page">
            <h1>Log in</h1>
            <Paper style={{ padding: 8 }}>
                <div className="form">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        error={usernameError}
                        helperText={usernameError ? "Empty username" : ""}
                        onChange={changeUsername}
                        value={userName}
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="UserName"
                        autoComplete="Username"
                        autoFocus
                        onKeyPress={handleEnter}
                    />
                    <TextField
                        variant="outlined"
                        error={passwordError}
                        helperText={passwordError ? "Empty password" : ""}
                        onChange={changePassword}
                        value={passWord}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onKeyPress={handleEnter}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => onClick()}
                        style={{ marginTop: 8 }}
                    >
                        Sign In
                    </Button>
                </div>
            </Paper>
            <Snackbar open={errorSnackOpen} autoHideDuration={3000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error">
                    Login failed, check username and password and try again!
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default LoginPage;