import { Container, Tabs, Tab, Paper, Typography, Divider, CircularProgress, IconButton } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../data/api";
import { useAuthState } from "../../state/AuthState";
import InfoRow from "../common/info-row";
import InfoRowTable from "../common/info-row-table";
import TabPanel from "../common/tab-panel";
//import Location from "../location/location";
import { createAuthMiddleWare } from "../../data/api"
import { startOfWeek, endOfWeek, startOfDay, endOfDay, startOfMonth, endOfMonth, formatISO, add, sub } from 'date-fns'
import BatteryInfo from "../battery/battery-info";
import  SwInfoPanel  from "./device-sw-info-tab";

import "./device-info-page.scss"
import { ACCESS_TOKEN } from "../../constants";
import { REFRESH_TOKEN } from "../../constants";

const intervals = [{ value: "all", label: "All" },
{ value: "day", label: "Day" },
{ value: "week", label: "Week" },
{ value: "month", label: "Month" },
{ value: "custom", label: "Custom" }];

const DeviceInfoPage = () => {
  const { dispatchAuth } = useAuthState();
  const history = useHistory();
  const [device, setDevice] = useState(null);
  const [batteryData, setBattery] = useState(null);
  //const locations = 0;
  const [locations, setLocations] = useState(null);
  //const [locationsData, setLocations] = useState(null);
  const [loadingDevice, setLoadingDevice] = useState(true);
  const [batteryHistoryLoading, setBatteryHistoryLoading] = useState(true);
  const [LocationsHistoryLoading, setLocationsHistoryLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [intervalStart, setIntervalStart] = useState(startOfWeek( new Date(), { weekStartsOn: 1 } ) );
  const [intervalEnd, setIntervalEnd] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }));
  const [activeInterval, setActiveInterval] = useState("week");
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const authMiddleWare = createAuthMiddleWare(dispatchAuth);
  const { deviceId } = useParams();

  useEffect(() => {
    fetch(`${baseUrl}/devices/${deviceId}/deviceinfo`, {
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    })
      .then(authMiddleWare)
      .then((data) => {
        if (data.status === 200) {
          return data.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        setDevice(json);
        setLoadingDevice(false);
      })
      .catch((e) => {
        console.error(e);
        setLoadingDevice(false);
      });

 fetch(`${baseUrl}/devices/${deviceId}/location`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      })
        .then(authMiddleWare)
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          } else {
            return null;
          }
        })
        .then((json) => {
          setLocations(json);
        })
        .catch((e) => {
          console.error(e);
        });

    if (activeInterval === "all") {
      fetch(`${baseUrl}/devices/${deviceId}/battery`, {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      })
        .then(authMiddleWare)
        .then((data) => data.json())
        .then((json) => {
          setBattery(json.items[0]);
          setBatteryHistoryLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setBatteryHistoryLoading(false);
        });
    } else {
      if(activeInterval === "custom"){
        fetch(
          `${baseUrl}/devices/${deviceId}/battery?&starttime=${formatISO(intervalStart).toString().slice(0, 19) + ".0000Z"}&endtime=${formatISO(intervalEnd).toString().slice(0, 19) + ".0000Z"}`,
          {
            headers: {
              Authorization: `bearer ${accessToken}`,
            },
          }
        )
          .then(authMiddleWare)
          .then((data) => data.json())
          .then((json) => {
            if (json) {
              setBattery(json.items[0]);
            }
            setBatteryHistoryLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setBatteryHistoryLoading(false);
          });
      }else{
        fetch(
          `${baseUrl}/devices/${deviceId}/battery?&starttime=${formatISO(intervalStart).toString().slice(0, 19) + ".0000Z"}&endtime=${formatISO(calculateEnd()).toString().slice(0, 19) + ".0000Z"}`,
          {
            headers: {
              Authorization: `bearer ${accessToken}`,
            },
          }
        )
          .then(authMiddleWare)
          .then((data) => data.json())
          .then((json) => {
            if (json) {
              setBattery(json.items[0]);
            }
            setBatteryHistoryLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setBatteryHistoryLoading(false);
          });
      }
    }
  }, [intervalStart, intervalEnd, activeInterval]);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleIntervalChange = (interval) => {
    setBatteryHistoryLoading(true);
    setActiveInterval(interval);
    switch (interval) {
      case "day": {
        setIntervalStart(startOfDay(new Date()));
        setIntervalEnd(endOfDay(new Date()));
        break;
      }
      case "month": {
        setIntervalStart(startOfMonth(new Date()));
        setIntervalEnd(endOfMonth(new Date()));
        break;
      }
      case "week": {
        setIntervalStart(startOfWeek(new Date(), { weekStartsOn: 1 }));
        setIntervalEnd(endOfWeek(new Date(), { weekStartsOn: 1 }));
        break;
      }
      case "all": {
        break;
      }
      default: {
        break;
      }
    }
  }

  const handleIntervalChangeRight = () => {
    setBatteryHistoryLoading(true);
    switch (activeInterval) {
      case "day": {
        setIntervalStart(startOfDay(add(intervalStart, { days: 1 })));
        setIntervalEnd(endOfDay(add(intervalEnd, { days: 1 })));
        break;
      }
      case "month": {
        setIntervalStart(startOfMonth(add(intervalEnd, { days: 1 })));
        setIntervalEnd(endOfMonth(add(intervalEnd, { months: 1 })));
        break;
      }
      case "week": {
        setIntervalStart(startOfWeek(add(intervalEnd, { days: 1 }), { weekStartsOn: 1 }));
        setIntervalEnd(endOfWeek(add(intervalEnd, { days: 1 }), { weekStartsOn: 1 }));
        break;
      }
      case "all": {
        break;
      }
      default: {
        break;
      }
    }
  }

  const handleIntervalChangeLeft = () => {
    setBatteryHistoryLoading(true);
    switch (activeInterval) {
      case "day": {
        setIntervalStart(startOfDay(sub(intervalStart, { days: 1 })));
        setIntervalEnd(endOfDay(sub(intervalEnd, { days: 1 })));
        break;
      }
      case "month": {
        setIntervalStart(startOfMonth(sub(intervalStart, { months: 1 })));
        setIntervalEnd(endOfMonth(sub(intervalEnd, { months: 1 })));
        break;
      }
      case "week": {
        setIntervalStart(startOfWeek(sub(intervalStart, { days: 1 }), { weekStartsOn: 1 }));
        setIntervalEnd(endOfWeek(sub(intervalStart, { days: 1 }), { weekStartsOn: 1 }));
        break;
      }
      case "all": {
        break;
      }
      default: {
        break;
      }
    }
  }

  const handleStartDayChange = (event) => {
    setIntervalStart(startOfDay(event));
    setActiveInterval("custom");
  }

  const handleEndDayChange = (event) => {
    setIntervalEnd(endOfDay(event));
    setActiveInterval("custom");
  }

  const calculateEnd = () => {
    switch(activeInterval){
      case "day": {
        return endOfDay(intervalStart);
      }
      case "month": {
        return endOfMonth(intervalStart);
      }
      case "week": {
        return endOfWeek(intervalStart);
      }
      default: {
        break;
      }
    }
  }
  const lastUsed = device && device.timeToLastUse ? new Date(device.timeToLastUse).toLocaleString() : "";
  const createdAt = device && device.timeToCreated ? new Date(device.timeToCreated).toLocaleString() : "";
  const lastLocated = device && device.location.time ? new Date(device.location.time).toLocaleString() : "";
    
    return (
    <Container maxWidth="md">
      <div className="info-header">
        <div className="header">
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <Typography style={{ margin: 24 }} variant="h3">
            Device
          </Typography>
        </div>
        <Typography variant="h4">S/N {device && device.serialNumber}</Typography>
          </div>
      <Paper className="info-paper">
        <Tabs value={tabValue} onChange={handleChange} aria-label="info-tabs" centered>
          <Tab label="Device Info" id="tab-1" />
          <Tab label="Battery Info" id="tab-2" />
          <Tab label="Software Info" id="tab-3" />
          <Tab label="Location" id="tab-4" />
        </Tabs>
        {loadingDevice ? <CircularProgress className="loading-bar" /> : <div>
          <TabPanel value={tabValue} index={0} key={0}>
            {device ? <div>
              <InfoRow label={"Serial number"} value={device.serialNumber} />
              <Divider />
              <InfoRow label={"Sales box code"} value={device.salesBoxCode} />
              <Divider />
              <InfoRow label={"HW id"} value={device.hardwareId} />
              <Divider />
              <InfoRow label={"Product code"} value={device.productCode} />
              <Divider />
              <InfoRowTable label={"Mac addresses"} value={device.macAddresses} />
              <Divider />
              <InfoRow label={"Last seen"} value={lastUsed} />
              <Divider />
              <InfoRow label={"Taken to use"} value={createdAt} />
              <Divider />
              <InfoRow label={"IMEI"} value={device.imei} />
              <Divider />
            </div> : !loadingDevice && <Typography variant="button">Device not found</Typography>}
          </TabPanel>
          <TabPanel value={tabValue} index={1} key={1}>
            <div align="left">Battery status {Date(device.lastBatteryData.timeToCreate)}</div>
          <InfoRow label={"Temperature"} value={device.lastBatteryData.temperature+' °C'} />
          <Divider />
          <InfoRow label={"Level"} value={device.lastBatteryData.level+' %'}/>
          <Divider />
          <InfoRow label={"Health"} value={device.lastBatteryData.stateOfHealth+' %'} />
          <Divider />
          <InfoRow label={"Charge max capacity"} value={device.lastBatteryData.chargeMaxCapacity+' mA'} />
          <Divider />
          <InfoRow label={"Voltage"} value={device.lastBatteryData.voltage+' mV'} />
          <Divider />
          <InfoRow label={"Cycle count"} value={device.lastBatteryData.cycleCount} />
          <Divider />
          <InfoRow label={"Serial number"} value={device.lastBatteryData.serialNumber} />
          <Divider />
          <InfoRow label={"Manufacturer"} value={device.lastBatteryData.manufacturer} />
          <Divider />
          <InfoRow label={"Part number"} value={device.lastBatteryData.partNumber} />
          <Divider />
          <InfoRow label={"Manufacturer date (yyyy-mm-dd)"} value={device.lastBatteryData.manufacturerDate} />
          <Divider />
          <BatteryInfo
            batteryHistoryLoading={batteryHistoryLoading}
              deviceId={deviceId}
              intervals={intervals}
              activeInterval={activeInterval}
              batteryData={batteryData}
              handleIntervalChange={handleIntervalChange}
              handleIntervalChangeRight={handleIntervalChangeRight}
              handleIntervalChangeLeft={handleIntervalChangeLeft}
              handleStartDayChange={handleStartDayChange}
              handleEndDayChange={handleEndDayChange}
              intervalStart={intervalStart}
              intervalEnd={intervalEnd}
              calculateEnd={calculateEnd}/>
          </TabPanel>
          <TabPanel value={tabValue} index={2} key={3}>
           {device ? <div>
             <SwInfoPanel device={device}/>
            </div> : !loadingDevice && <Typography variant="button">Device not found</Typography>}
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
           {device ? <div>
              <div align="left">Location status {Date(lastLocated)}</div>
              <InfoRow label={"Location: "} value={"lat: " + device.location.lat + " , long: " + device.location.lng} />
              <Divider />
              <InfoRow label={"Location source: "} value={device.location.source} />
              <Divider />
              <InfoRow label={"Location accuracy: "} value={device.location.accuracy+' m'} />
              <Divider />
              <InfoRow label={"Speed: "} value={device.location.speed+' Km/h'} />
              <Divider />
              <InfoRow label={"Altitude: "} value={device.location.altitude+' m'} />
              <Divider />
              <InfoRow label={"Bearing: "} value={device.location.bearing+' degrees'} />
              <Divider />
              <div> </div>
               {/*<Location
                serialNumber={device.serialNumber}
                mapCenterLatitude={device.location.lat}
                mapCenterLongitude={device.location.lng}
                deviceTime={lastLocated}
                locData={locations}
                zoomState={10}
                mapStyles={{width: '75%',height: '70%'}}
           />*/}
            </div> : !loadingDevice && <Typography variant="button">Device not found</Typography>}
          </TabPanel>
        </div>}

      </Paper>
    </Container>
  );
}

export default DeviceInfoPage;